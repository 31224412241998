import React, { useState, useEffect } from 'react'
import { MultipleNotification } from '@monetization/hpaip-ui-shared-components'

interface PageProps {
  printerProps?: any
  errorNotification?: boolean
  isPaperAdded?: boolean
  revertPlan?: boolean
}

export interface NotificationData {
  title?: string
  description?: string
  notificationID?: string
  showCloseButton?: boolean
  notificationType?: any
}

const NotificationContainer: React.FC<PageProps> = ({
  printerProps,
  errorNotification,
  isPaperAdded,
  revertPlan
}) => {
  const [onFlyNotifications, setOnFlyNotifications] = useState<
    NotificationData[]
  >([])
  const [updatedNotificationsList, setUpdatedNotificationsList] = useState([])
  useEffect(() => {
    if (revertPlan) {
      setOnFlyNotifications([
        {
          notificationID: 'PlanRevertedNotification',
          title: 'Your plan was successfully reverted',
          notificationType: 'positive',
          showCloseButton: true
        }
      ])
    } else if (errorNotification) {
      setOnFlyNotifications([
        {
          notificationID: 'UpdatePlanFailedNotification',
          title: 'We were unable to update your plan. Please try again.',
          description:
            'For assistance, visit <a href="https://support.hp.com/contact/select-product?originid=myaccount" target="_blank"> 24/7 Pro live support</a>',
          notificationType: 'warning',
          showCloseButton: true
        }
      ])
    } else if (isPaperAdded) {
      setOnFlyNotifications([
        {
          notificationID: 'UpdatePlanPaperAddOnNotification',
          title: 'Your Paper Add-on Service was successfully added',
          description:
            '<p>Congratulations! You will receive an email when your paper is shipped. Your Paper Add-on Service billing will start when you receive your first shipment. It will be prorated to match your HP All-In Plan billing cycle.</p>',
          notificationType: 'positive',
          showCloseButton: true
        }
      ])
    } else {
      setOnFlyNotifications([])
    }
  }, [errorNotification, revertPlan, isPaperAdded])
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const rescindCanel = params.get('rescindPaperCanel')
    if (rescindCanel === 'success') {
      const notificationConfig = {
        notificationID: 'UpdateplanRescindNotification',
        title: 'Your Paper Add-on Service has been successfully resumed',
        description:
          'The details are below. If you experience any issues, please visit <a href="https://support.hp.com/contact/select-product?originid=myaccount" target="_blank"> 24/7 Pro live support.</a>',
        notificationType: 'positive',
        showCloseButton: true
      }

      setUpdatedNotificationsList([notificationConfig])
      const newUrl = window.location.pathname
      if (params.has('rescindPaperCanel')) {
        params.delete('rescindPaperCanel')
      }
      window.history.replaceState({}, '', newUrl)
    }
  }, [printerProps?.notificationsList])
  const multiNotificationArray = [
    ...onFlyNotifications,
    ...(printerProps?.notificationsList || []),
    ...updatedNotificationsList
  ]
  return (
    <MultipleNotification
      multiNotificationArray={multiNotificationArray}
      screenPath="/ReactPaasDashboardUpdatePlan/UpdatePlan/"
    />
  )
}

export default NotificationContainer
